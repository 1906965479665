<template>
  <div class="w-full pt-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
            class="ml-2"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4">
          Employee Appraisal
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template v-if="isLoading">
      <div class="mt-20 h-full">
        <Loader size="xxs" :loader-image="false" />
      </div>
    </template>
    <template v-else>
      <template>
        <div class="mx-5 mt-10">
          <Tab
            class="mt-6 mx-3"
            :tabs="tabsItems"
            :active-tab="activeTab"
            @currentTab="activateTab($event)"
            :border="true"
          />
          <div v-if="startAppraisal">
            <template v-if="!finalAppraisal">
              <div class="mt-20 h-full" v-if="loadingAppraisal">
                <Loader size="xxs" :loader-image="false" />
              </div>

              <div class="w-full flex flex-col" v-else>
                <Card class="p-5">
                  <div
                    class="w-full flex flex-col"
                    v-if="templateKPATypes.length > 0"
                  >
                    <div class="w-full flex p-0 justify-between items-center">
                      <h6 class="font-bold text-lg leading-normal">
                        {{ activeTab }}
                      </h6>
                      <div @click="getComments(payload.employeeAgreementId)">
                        <div
                          class="flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon size="xs" icon-name="comment-outline" />
                          <span class="leading-5 font-sm font-normal text-jet"
                            >Comment</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col font-black text-xs">
                      <div
                        class="w-full flex px-3 py-1 my-3 justify-between align-center bg-cultured rounded-md"
                      >
                        <div class="w-1/2">
                          <p
                            class="leading-5 tracking-wide uppercase text-romanSilver"
                          >
                            KPA Types
                          </p>
                        </div>
                        <div class="w-1/2 mr-16 flex justify-end gap-8">
                          <p
                            class="leading-5 tracking-wide uppercase text-romanSilver"
                          >
                            Allocated Weight
                          </p>
                          <p
                            class="leading-5 tracking-wide mr-1 uppercase text-romanSilver"
                          >
                            employee
                          </p>
                          <p
                            class="leading-5 tracking-wide ml-2 uppercase text-romanSilver"
                          >
                            line manager
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="w-full h-auto flex flex-col" v-if="loaded">
                      <Accordion
                        v-for="(kpaTypes, i) in templateKPATypes"
                        :key="i"
                        class="mx-3 mt-6"
                        show-divider
                        :is-open="false"
                      >
                        <template v-slot:title>
                          <span class="font-bold">{{ kpaTypes.name }}</span>
                        </template>
                        <template v-slot:block>
                          <div
                            class="flex justify-center items-center gap-2 w-3/7 mr-3"
                          >
                            <div
                              class="font-bold bg-ghostWhite px-12 py-1 rounded-md w-38"
                            >
                              <span class="text-darkPurple text-xs font-black">
                                {{ kpaTypes.weight }}%
                              </span>
                            </div>
                            <div
                              class="font-bold bg-ghostWhite py-2 w-32 rounded-md uppercase"
                            >
                              <span
                                class="text-xs w-full flex justify-center font-bold"
                              >
                                {{
                                  !getAverageSummary(kpaTypes.id, kpaTypes.name)
                                    ? "---"
                                    : getAverageSummary(kpaTypes.id, kpaTypes.name)
                                }}
                              </span>
                            </div>
                            <div
                              class="w-32 font-bold bg-ghostWhite py-2 rounded-md uppercase"
                            >
                              <span
                                class="text-xs w-full flex justify-center font-bold"
                              >
                                {{
                                  getKPATotalManagerAppraisal(kpaTypes.name) ===
                                  ""
                                    ? "---"
                                    : getKPATotalManagerAppraisal(kpaTypes.name)
                                }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <div class="w-full">
                          <ScrollContainer height="1000px">
                            <Table
                              :headers="KPAHeader"
                              :items="kpaTypes.kpas"
                              :row-header="true"
                              :has-footer="true"
                            >
                              <template v-slot:kpis>
                                <div
                                  class="label flex"
                                  v-for="(n, rating) in Ratings"
                                  :key="rating"
                                >
                                  <div
                                    class="mr-2 ml-4 -mt-1 text-base font-semibold w-52"
                                  >
                                    {{ rating }}
                                  </div>
                                </div>
                              </template>
                              <template v-slot:selfRating>
                                <div class="w-72 flex">
                                  <div
                                    class="ml-4 -mt-1 text-sm font-semibold w-3/4"
                                  >
                                    Flag
                                  </div>
                                </div>
                              </template>
                              <template v-slot:managerRating>
                                <div class="w-72 flex">
                                  <div
                                    class="ml-4 -mt-1 text-sm font-semibold w-3/4"
                                  >
                                    Flag
                                  </div>
                                </div>
                              </template>
                              <template v-slot:item="{ item }">
                                <div v-if="item.name" class="py-2">
                                  <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                    {{ item.data.name }}
                                  </div>
                                </div>
                                <div v-else-if="item.objective">
                                  <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                    {{ item.data.objective }}
                                  </div>
                                </div>

                                <div v-else-if="item.kpis" class="flex">
                                  <div
                                    class="label flex"
                                    v-for="(n, rating) in item.data.kpis"
                                    :key="rating"
                                  >
                                    <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                      {{ item.data.kpis[rating].criteria }}
                                    </div>
                                  </div>
                                </div>

                                <div v-else-if="item.weight">
                                  <div class="label">
                                    <div
                                      class="mr-2 ml-4 -mt-1 w-32 text-base font-black text-blueCrayola"
                                    >
                                      {{ item.data.weight }}
                                    </div>
                                  </div>
                                </div>

                                <div v-else-if="item.selfRating">
                                  <div class="w-72 flex">
                                    <div
                                      class="ml-4 -mt-1 text-sm font-semibold w-3/4"
                                    >
                                      <select
                                        class="mr-2 -ml-1 mt-1 input-select"
                                        v-model="item.data.ratingId"
                                        @change="
                                          changeRating(
                                            $event,
                                            item,
                                            templateKPATypes,
                                            kpaTypes.id,
                                            'mid_review'
                                          )
                                        "
                                        @input="setInput(kpaTypes.name)"
                                        v-if="
                                          startAppraisal && !disableAppraisal
                                        "
                                      >
                                        <option value="" disabled selected>
                                          --Rating--
                                        </option>
                                        <option
                                          v-for="option in flagOptions"
                                          :value="option.id"
                                          :key="option.id"
                                        >
                                          {{ option.name }}
                                        </option>
                                      </select>
                                      <c-select
                                        placeholder="--Rating--"
                                        variant="w-full"
                                        class="mr-2 -ml-1 -mt-1"
                                        type="number"
                                        :disabled="true"
                                        :options="ratingOptions"
                                        v-model="item.data.ratingId"
                                        v-if="
                                          !startAppraisal || disableAppraisal
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div v-else-if="item.managerRating">
                                  <div class="w-72 flex">
                                    <div
                                      class="ml-4 -mt-1 text-sm font-semibold w-3/4"
                                    >
                                      <c-select
                                        placeholder="--Rating--"
                                        variant="w-full"
                                        class="mr-2 -ml-1 -mt-1"
                                        type="number"
                                        :disabled="employee"
                                        :options="flagOptions"
                                        v-model="item.data.managerRating"
                                        v-if="employee"
                                      />

                                      <select
                                        @change="
                                          changeManagerRating(
                                            $event,
                                            item,
                                            templateKPATypes,
                                            kpaTypes.id,
                                            'mid_review'
                                          )
                                        "
                                        @input="setInput(kpaTypes.name)"
                                        class="mr-2 -ml-1 mt-1 input-select"
                                        v-model="item.data.managerRating"
                                        v-else
                                      >
                                        <option value="" disabled selected>
                                          --Rating--
                                        </option>
                                        <option
                                          v-for="option in flagOptions"
                                          :value="option.id"
                                          :key="option.id"
                                        >
                                          {{ option.name }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div v-else-if="item.outcome">
                                  <div class="label ml-6">
                                    <c-text
                                      placeholder="--Enter--"
                                      variant="w-full"
                                      class="mr-2 -ml-1 -mt-1 w-72"
                                      :disabled="employee"
                                      v-model="item.data.outcome"
                                    />
                                  </div>
                                </div>
                              </template>
                              <template v-slot:footer>
                                <td />
                                <td />
                                <td />
                                <td>
                                  <div
                                    class="w-full flex justify-end p-3 -ml-12"
                                  >
                                    <span class="text-lg font-black text-end">
                                      Summary
                                    </span>
                                  </div>
                                </td>
                                <td
                                  v-if="
                                    appraisalDisplay === 'employee' ||
                                    appraisalDisplay === 'both'
                                  "
                                >
                                  <div class="w-full flex">
                                    <div
                                      class="w-3/4 ml-3 justify-end flex p-1 bg-ghostWhite"
                                    >
                                      <span
                                        class="text-lg text-center font-black w-full"
                                        style="border-radius: 5px"
                                      >
                                        {{
                                          !getAverageSummary(kpaTypes.id, kpaTypes.name)
                                            ? "---"
                                            : getAverageSummary(kpaTypes.id, kpaTypes.name)
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  v-if="
                                    appraisalDisplay === 'manager' ||
                                    appraisalDisplay === 'both'
                                  "
                                >
                                  <div class="w-full flex">
                                    <div
                                      class="w-3/4 ml-3 flex p-1 bg-ghostWhite"
                                    >
                                      <span
                                        class="text-lg text-center font-black w-full"
                                        style="border-radius: 5px"
                                      >
                                        {{
                                          getKPATotalManagerAppraisal(
                                            kpaTypes.name
                                          ) === ""
                                            ? "---"
                                            : getKPATotalManagerAppraisal(
                                                kpaTypes.name
                                              )
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </template>
                            </Table>
                          </ScrollContainer>
                        </div>
                      </Accordion>
                    </div>
                  </div>

                  <div v-else>
                    <div
                      class="w-full flex flex-col justify-center items-center mt-20"
                    >
                      <icon
                        icon-name="empty_agreement"
                        size="l"
                        style="width: 300px"
                      />
                      <div class="w-1/2 text-base text-center">
                        Kindly submit your performance agreement to start your
                        appraisal
                      </div>
                    </div>
                  </div>
                </Card>
                <div class="flex flex-col w-full" v-if="loaded">
                  <template>
                    <div
                      class="w-full flex p-5 shadow rounded-md my-2 justify-between items-center relative text-right whitespace-nowrap"
                    >
                      <span
                        class="font-bold text-lg leading-5 text-left text-darkPurple tracking-wider"
                      >
                        Summary
                      </span>
                      <div
                        class="absolute left-0 right-0 border border-solid border-gray-400 h-0 ml-36 mr-52"
                      />
                      <div
                        class="flex justify-end gap-10 bg-white relative px-5"
                      >
                        <div class="flex flex-col items-start leading-5">
                          <span
                            class="text-gray-400 text-xs text-center font-black uppercase"
                            >Appraisal Flag</span
                          >
                          <span
                            class="font-semibold text-base text-darkPurple"
                            >{{ approvedScore }}</span
                          >
                        </div>
                        <div class="flex flex-col items-start leading-5">
                          <span
                            class="text-gray-400 text-xs text-center font-black uppercase"
                            >self-appraisal</span
                          >
                          <span
                            class="font-semibold text-base text-darkPurple"
                            >{{ selfAppraisalScore }}</span
                          >
                        </div>
                        <div class="flex flex-col items-start leading-5">
                          <span
                            class="text-gray-400 text-xs text-center font-black uppercase"
                            >line manager</span
                          >
                          <span
                            class="font-semibold text-base text-darkPurple"
                            >{{ lineManagerAppraisalScore }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="loaded">
                    <div
                      class="w-full flex p-3 gap-4 justify-between items-center"
                      v-if="!appraisalEnd"
                    >
                      <div class="flex justify-start gap-2">
                        <Button
                          label="Share with Appraiser"
                          variant="primary"
                          @onClick="submitAppraiser('active')"
                          v-if="appraisalStatus !== 'submitted'"
                        />
                        <Button
                          label="Save as Draft"
                          variant="secondary btn-border"
                          v-if="appraisalState !== 'active'"
                          @onClick="submitAppraiser('draft')"
                        />
                        <Button
                          label="Cancel"
                          @onClick="$router.back()"
                          class="button-class-secondary btn-border"
                          v-if="appraisalStatus !== 'submitted'"
                        />
                      </div>
                      <div
                        class="w-5/12 flex mx-4 p-2 rounded-md bg-yellow-50 border border-yellow-500"
                        v-if="appraisalState !== 'active'"
                      >
                        <Icon
                          class-name="text-carrotOrange mx-auto self-center"
                          size="xs"
                          icon-name="icon-info"
                        />
                        <span class="flex text-sm mx-3">
                          Once submitted, your manager(s) can now give their own
                          appraisals, and approve upon agreement.
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="mt-20 h-full" v-if="loadingAppraisal">
                <Loader size="xxs" :loader-image="false" />
              </div>

              <div class="w-full flex flex-col" v-else>
                <Card class="p-5">
                  <div
                    class="w-full flex flex-col"
                    v-if="templateKPATypes.length > 0"
                  >
                    <div
                      class="w-full flex p-0 mt-2 justify-between items-center"
                    >
                      <h6 class="font-bold text-lg leading-normal">
                        {{ activeTab }}
                      </h6>
                      <div @click="getComments(payload.employeeAgreementId)">
                        <div
                          class="flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon size="xs" icon-name="comment-outline" />
                          <span class="leading-5 font-sm font-normal text-jet"
                            >Comment</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col font-black text-xs mt-1">
                      <div
                        class="w-full flex px-3 py-1 my-3 justify-between align-center bg-cultured rounded-md"
                      >
                        <div class="w-1/2">
                          <p
                            class="leading-5 tracking-wide uppercase text-romanSilver"
                          >
                            KPA Types
                          </p>
                        </div>
                        <div class="w-1/2 mr-12 flex justify-end gap-8">
                          <p
                            class="leading-5 tracking-wide mr-2 uppercase text-romanSilver"
                          >
                            Allocated Weight
                          </p>
                          <p
                            class="leading-5 tracking-wide mr-2 uppercase text-romanSilver"
                          >
                            employee
                          </p>
                          <p
                            class="leading-5 tracking-wide uppercase text-romanSilver"
                          >
                            line manager
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="w-full h-auto flex flex-col" v-if="loaded">
                      <Accordion
                        v-for="(kpaTypes, i) in templateKPATypes"
                        :key="i"
                        class="mt-6"
                        show-divider
                        :is-open="false"
                      >
                        <template v-slot:title>
                          <span class="font-bold">{{ kpaTypes.name }}</span>
                        </template>
                        <template v-slot:block>
                          <div
                            class="flex justify-center items-center gap-2 w-3/7 mr-3"
                          >
                            <div
                              class="font-bold bg-ghostWhite px-12 py-1 rounded-md w-38"
                            >
                              <span class="text-darkPurple text-xs font-black">
                                {{ kpaTypes.weight }}%
                              </span>
                            </div>
                            <div
                              class="font-black bg-ghostWhite py-2 w-32 rounded-md uppercase"
                            >
                              <span
                                class="text-xs w-full flex justify-center font-black"
                              >
                                {{
                                  getKPATotalScore(kpaTypes.id, kpaTypes) === ""
                                    ? "---"
                                    : getKPATotalScore(kpaTypes.id, kpaTypes)
                                }}
                              </span>
                            </div>
                            <div
                              class="w-32 font-black bg-ghostWhite py-2 rounded-md uppercase"
                            >
                              <span
                                class="text-xs w-full flex justify-center font-bold"
                              >
                                {{
                                  getKPATotalManagerScore(kpaTypes.id, kpaTypes) === ""
                                    ? "---"
                                    : getKPATotalManagerScore(kpaTypes.id, kpaTypes)
                                }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <div class="w-full">
                          <ScrollContainer height="1000px">
                            <Table
                              :headers="KPAHeader"
                              :items="kpaTypes.kpas"
                              :row-header="true"
                              :has-footer="true"
                            >
                              <template v-slot:kpis>
                                <div
                                  class="label flex"
                                  v-for="(n, rating) in Ratings"
                                  :key="rating"
                                >
                                  <div
                                    class="mr-2 ml-4 -mt-1 text-base font-semibold w-52"
                                  >
                                    {{ rating }}
                                  </div>
                                </div>
                              </template>

                              <template v-slot:selfRating>
                                <div class="w-72 flex">
                                  <div
                                    class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                  >
                                    Rating
                                  </div>
                                  <div
                                    class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                  >
                                    Score
                                  </div>
                                </div>
                              </template>

                              <template v-slot:item="{ item }">
                                <div v-if="item.name" class="py-2">
                                  <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                    {{ item.data.name }}
                                  </div>
                                </div>
                                <div v-else-if="item.objective">
                                  <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                    {{ item.data.objective }}
                                  </div>
                                </div>

                                <div v-else-if="item.kpis" class="flex">
                                  <div
                                    class="label flex"
                                    v-for="(n, rating) in item.data.kpis"
                                    :key="rating"
                                  >
                                    <div class="mr-2 ml-4 -mt-1 text-base w-52">
                                      {{ item.data.kpis[rating].criteria }}
                                    </div>
                                  </div>
                                </div>

                                <div v-else-if="item.weight">
                                  <div class="label">
                                    <div
                                      class="mr-2 ml-4 -mt-1 w-32 text-base font-black text-blueCrayola"
                                    >
                                      {{ item.data.weight }}
                                    </div>
                                  </div>
                                </div>

                                <div v-else-if="item.selfRating">
                                  <div class="w-72 flex">
                                    <div
                                      class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                    >
                                      <select
                                        @change="
                                          changeRating(
                                            $event,
                                            item,
                                            templateKPATypes,
                                            kpaTypes.id,
                                            'final_review'
                                          )
                                        "
                                        class="mr-2 -ml-1 mt-1 input-select"
                                        v-model="item.data.ratingId"
                                        v-if="
                                          startAppraisal && !disableAppraisal
                                        "
                                      >
                                        <option value="" disabled selected>
                                          --Rating--
                                        </option>
                                        <option
                                          v-for="option in ratingOptions"
                                          :value="option.id"
                                          :key="option.id"
                                        >
                                          {{ option.name }}
                                        </option>
                                      </select>
                                      <c-select
                                        placeholder="--Rating--"
                                        variant="w-full"
                                        class="mr-2 -ml-1 -mt-1"
                                        type="number"
                                        :disabled="true"
                                        :options="ratingOptions"
                                        v-model="item.data.ratingId"
                                        v-if="
                                          !startAppraisal || disableAppraisal
                                        "
                                      />
                                    </div>
                                    <div
                                      class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                    >
                                      <c-text
                                        placeholder="--Score--"
                                        variant="w-full"
                                        class="mr-2 -ml-1 -mt-1"
                                        type="number"
                                        :disabled="true"
                                        v-model="item.data.selfScore"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div v-else-if="item.managerRating">
                                  <div class="w-72 flex">
                                    <div
                                      class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                    >
                                      <select
                                        @change="
                                          changeManagerRating(
                                            $event,
                                            item,
                                            templateKPATypes,
                                            'final_review'
                                          )
                                        "
                                        class="mr-2 -ml-1 mt-1 input-select"
                                        :disabled="employee"
                                        :options="ratingOptions"
                                        v-model="item.data.managerRating"
                                        v-if="employee"
                                      >
                                        <option value="" disabled selected>
                                          --Rating--
                                        </option>
                                        <option
                                          v-for="option in ratingOptions"
                                          :value="option.id"
                                          :key="option.id"
                                        >
                                          {{ option.name }}
                                        </option>
                                      </select>


                                      <select
                                        @change="
                                          changeManagerRating(
                                            $event,
                                            item,
                                            templateKPATypes,
                                            'final_review'
                                          )
                                        "
                                        class="mr-2 -ml-1 mt-1 input-select"
                                        v-model="item.data.managerRating"
                                        v-else
                                      >
                                        <option value="" disabled selected>
                                          --Rating--
                                        </option>
                                        <option
                                          v-for="option in ratingOptions"
                                          :value="option.id"
                                          :key="option.id"
                                        >
                                          {{ option.name }}
                                        </option>
                                      </select>
                                    </div>
                                    <div
                                      class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                    >
                                      <c-text
                                        placeholder="--Score--"
                                        variant="w-full"
                                        class="mr-2 -ml-1 -mt-1"
                                        type="number"
                                        :disabled="true"
                                        v-model="item.data.managerScore"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div v-else-if="item.outcome">
                                  <div class="label ml-6">
                                    <c-text
                                      placeholder="--Enter--"
                                      variant="w-full"
                                      class="mr-2 -ml-1 -mt-1 w-72"
                                      :disabled="employee"
                                      v-model="item.data.outcome"
                                    />
                                  </div>
                                </div>
                              </template>

                              <template v-slot:footer>
                                <td />
                                <td />
                                <td />
                                <td>
                                  <div
                                    class="w-full flex justify-end p-3 -ml-4"
                                  >
                                    <span class="text-lg font-black text-end">
                                      Total Scores
                                    </span>
                                  </div>
                                </td>
                                <td
                                  v-if="
                                    appraisalDisplay === 'employee' ||
                                    appraisalDisplay === 'both'
                                  "
                                >
                                  <div class="w-full flex">
                                    <div class="w-1/2" />
                                    <div
                                      class="w-32 justify-end flex p-1 bg-ghostWhite"
                                    >
                                      <span
                                        class="text-lg text-center font-black w-full"
                                        style="border-radius: 5px"
                                      >
                                        {{
                                          getKPATotalScore(
                                            kpaTypes.id,
                                            kpaTypes
                                          ) === ""
                                            ? "---"
                                            : getKPATotalScore(
                                                kpaTypes.id,
                                                kpaTypes
                                              )
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td></td>
                                <td
                                  v-if="
                                    appraisalDisplay === 'manager' ||
                                    appraisalDisplay === 'both'
                                  "
                                >
                                  <div
                                    class="w-32 -ml-40 flex p-1 bg-ghostWhite"
                                  >
                                    <span
                                      class="text-lg text-center font-black w-full"
                                      style="border-radius: 5px"
                                    >
                                      {{
                                        getKPATotalManagerScore(
                                          kpaTypes.id,
                                          kpaTypes
                                        ) === ""
                                          ? "---"
                                          : getKPATotalManagerScore(
                                              kpaTypes.id,
                                              kpaTypes
                                            )
                                      }}
                                    </span>
                                  </div>
                                </td>
                              </template>
                            </Table>
                          </ScrollContainer>
                        </div>
                      </Accordion>
                    </div>
                  </div>

                  <div v-else>
                    <div
                      class="w-full flex flex-col justify-center items-center mt-20"
                    >
                      <icon
                        icon-name="empty_agreement"
                        size="l"
                        style="width: 300px"
                      />
                      <div class="w-1/2 text-base text-center">
                        Kindly submit your performance agreement to start your
                        appraisal
                      </div>
                    </div>
                  </div>
                </Card>

                <div class="flex flex-col w-full" v-if="loaded">
                  <template>
                    <div
                      class="w-full flex p-5 shadow rounded-md my-2 justify-between items-center relative text-right whitespace-nowrap"
                    >
                      <span
                        class="font-bold text-lg leading-5 text-left text-darkPurple tracking-wider"
                      >
                        Final Score
                      </span>
                      <div
                        class="absolute left-0 right-0 border border-solid border-gray-400 h-0 ml-36 mr-52"
                      />
                      <div
                        class="flex justify-end gap-10 bg-white relative px-5"
                      >
                        <div class="flex flex-col items-start leading-5">
                          <span
                            class="text-gray-400 text-xs text-center font-black uppercase"
                            >Approved Score</span
                          >
                          <span
                            class="font-semibold text-base text-darkPurple"
                            >{{ approvedScore }}</span
                          >
                        </div>
                        <div class="flex flex-col items-start leading-5">
                          <span
                            class="text-gray-400 text-xs text-center font-black uppercase"
                            >self-appraisal</span
                          >
                          <span
                            class="font-semibold text-base text-darkPurple"
                            >{{ selfAppraisalScore }}</span
                          >
                        </div>
                        <div class="flex flex-col items-start leading-5">
                          <span
                            class="text-gray-400 text-xs text-center font-black uppercase"
                            >line manager</span
                          >
                          <span
                            class="font-semibold text-base text-darkPurple"
                            >{{ lineManagerAppraisalScore }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div
                      class="w-full flex p-3 gap-4 justify-between items-center"
                      v-if="!appraisalEnd"
                    >
                      <div class="flex justify-start gap-2">
                        <Button
                          label="Share with Appraiser"
                          variant="primary btn-bg"
                          @onClick="submitAppraiser('active')"
                          v-if="appraisalStatus !== 'submitted'"
                        />
                        <Button
                          label="Save as Draft"
                          variant="secondary text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                          v-if="appraisalState !== 'active'"
                          @onClick="submitAppraiser('draft')"
                        />
                        <Button
                          label="Cancel"
                          @onClick="$router.back()"
                          class="button-class-secondary"
                          v-if="appraisalStatus !== 'submitted'"
                        />
                      </div>
                      <div
                        class="w-5/12 flex mx-4 p-2 rounded-md bg-yellow-50 border border-yellow-500"
                        v-if="appraisalState !== 'active'"
                      >
                        <Icon
                          class-name="text-carrotOrange mx-auto self-center"
                          size="xs"
                          icon-name="icon-info"
                        />
                        <span class="flex text-sm mx-3">
                          Once submitted, your manager(s) can now give their own
                          appraisals, and approve upon agreement.
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>

          <div class="w-full" v-if="appraisalMessage !== '' && !startAppraisal">
            <div class="w-full flex flex-col justify-center items-center mt-20">
              <icon icon-name="empty_agreement" size="l" style="width: 300px" />
              <div class="w-1/2 text-base text-center">
                {{ appraisalMessage }}
              </div>
            </div>
          </div>

          <div
            class="fit-content flex mx-4 p-2 rounded-md bg-yellow-50 border border-yellow-500"
            v-if="appraisalMessage !== '' && startAppraisal && loaded"
          >
            <Icon
              class-name="text-carrotOrange"
              size="xs"
              icon-name="icon-info"
            />
            <span class="flex text-sm mx-3">
              {{ appraisalMessage }}
            </span>
          </div>
        </div>
      </template>
    </template>

    <template>
      <RightSideBar
        v-if="isOpenComments"
        @submit="sendComment"
        @close="isOpenComments = false"
        button-class="bg-dynamicBackBtn text-white"
      >
        <template v-slot:title>
          <p class="text-darkPurple font-bold text-lg leading-5 p-0">
            Comments
          </p>
        </template>
        <div class="w-full flex">
          <div class="flex flex-col w-full" style="min-height: 70vh">
            <CommentsBox
              :comments="allComments"
              @markRead="markAsRead($event)"
              @deleteComment="deleteComments($event)"
              :loading="loadComments"
            />
            <div class="-mb-12" style="margin-top: auto">
              <CTextarea
                placeholder="--Message--"
                variant="primary"
                class="mt-2"
                value=""
                :row="8"
                :height="90"
                :col="30"
                :input="null"
                v-model="comment"
              />
            </div>
          </div>
        </div>
      </RightSideBar>
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Button from "@scelloo/cloudenly-ui/src/components/button";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import ScrollContainer from "@/components/ScrollContainer";
import Loader from "@/components/Loader";
import Accordion from "@/components/Accordion";
import Table from "@/components/Table";
import Icon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import CommentsBox from "@/components/commentsBox";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  name: "PerformanceEmployeeAppraisals",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Tab,
    Breadcrumb,
    BackButton,
    Button,
    CSelect,
    CommentsBox,
    ScrollContainer,
    CText,
    Loader,
    Card,
    Accordion,
    Table,
    Icon,
    CTextarea,
    RightSideBar,
  },
  data() {
    return {
      openModal: false,
      isLoading: true,
      loaded: false,
      loadingAppraisal: true,
      isOpenComments: false,
      loadComments: true,
      isManager: false,
      tabsItems: [],
      activeTab: `${this.$route.params.cycle}`,
      KPAHeader: [
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "KPI", value: "kpis" },
        { title: "Weight (%)", value: "weight" },
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Appraisals",
          href: "performance-appraisals",
          id: "PerformanceAppraisals",
        },
        {
          disabled: false,
          text: "Employee Appraisal",
          href: "employee-appraisal",
          id: "EmployeeAppraisal",
        },
      ],
      startAppraisal: false,
      appraisalStartDate: "",
      appraisalEndDate: "",
      appraisalMessage: "",
      appraisalEnd: false,
      templateKPATypes: [],
      Ratings: [],
      ratingOptions: [],
      flagOptions: [],
      employeeAppraisals: [],
      selfAppraisalScore: "",
      lineManagerAppraisalScore: "",
      approvedScore: "",
      appraisalStatus: "",
      appraisalFlag: "",
      appraisalState: "",
      checkEditAppraisal: false,
      disableAppraisal: false,
      appraisalDisplay: "",
      employee: false,
      finalAppraisal: false,
      cyclesArr: [],
      kpaAppraisalSummary: [],
      inputChange: [],
      allComments: [],
      comment: "",
      payload: {
        employeeAgreementId: "",
        orgId: null,
        goalId: null,
        year: "",
        employeeId: "",
        appraisal_status: "",
        status: "draft",
        cycleId: "",
        appraisalFor: "",
        appraisalBy: "",
        share: false,
        reviewType: "",
        kpa_types: [],
      },
    };
  },

  methods: {
    activateTab(tab) {
      const tabIndex = this.tabsItems.indexOf(tab, 0);
      this.inputChange = [];

      if (tabIndex === this.tabsItems.length - 1) {
        this.finalAppraisal = true;
        this.payload.reviewType = "final_review";
      } else {
        this.finalAppraisal = false;
        this.payload.reviewType = "mid_review";
      }

      this.cyclesArr.filter((cycle) => {
        if (cycle.name === tab) {
          this.payload.cycleId = cycle.id;
        }
        return {};
      });

      this.employeeAppraisals = [];
      this.templateKPATypes = [];
      this.loaded = false;
      this.loadingAppraisal = true;
      this.startAppraisal = false;
      this.appraisalMessage = "";
      this.appraisalEnd = false;

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        appraisal[0].cycles.map((cycle) => {
          if (cycle.name === this.activeTab) {
            const appraisalStartDate = cycle.appraisal_starts;
            const appraisalEndDate = cycle.appraisal_ends;

            this.checkAppraisalStart(appraisalStartDate, appraisalEndDate);
          }
          return {};
        });
      });

      this.activeTab = tab;
    },

    setInput(kpaTypeName) {
      if (!this.inputChange.includes(kpaTypeName)) {
        this.inputChange.push(kpaTypeName)
      }
    },

    changeRating(event, item, kpaTypes, kpaTypeId, reviewType) {
      const ratingId = event.target.value;
      const { kpaId } = item.data;
      let sum = 0;
      let totalWeight = 0;
      let totalSum = 0;

      this.$_calculateRatingScore(kpaId, ratingId, reviewType).then(
        (response) => {
          const itemVariable = item;
          itemVariable.data.selfScore = response.data.data.score;

          kpaTypes.forEach((type) => {
            if (type.id === kpaTypeId) {
            type.kpas.forEach((items) => {
              sum += items.selfScore;
              totalWeight += items.weight;
            });
            }
          });

          kpaTypes.forEach((type) => {
            type.kpas.forEach((items) => {
              totalSum += items.selfScore;
            });
          });

           const weightedScore = (sum / totalWeight) * 100;

         if (reviewType === "mid_review") {
            this.getAverageAppraisal(weightedScore.toFixed(2) || 0, kpaTypeId);
          } else {
            this.selfAppraisalScore = totalSum.toFixed(2);
          }
        }
      );
    },

    changeManagerRating(event, item, kpaTypes, kpaTypeId, reviewType) {
      const ratingId = event.target.value;
      const { kpaId } = item.data;
      let sum = 0;
      let totalWeight = 0;
      let totalSum = 0;

      this.$_calculateRatingScore(kpaId, ratingId, reviewType).then(
        (response) => {
          const itemVariable = item;
          itemVariable.data.managerScore = response.data.data.score;

          kpaTypes.forEach((type) => {
            if (type.id === kpaTypeId) {
            type.kpas.forEach((items) => {
              sum += items.managerScore;
              totalWeight += items.weight;
            });
            }
          });

          kpaTypes.forEach((type) => {
            type.kpas.forEach((items) => {
              totalSum += items.managerScore;
            });
          });

           const weightedScore = (sum / totalWeight) * 100;

         if (reviewType === "mid_review") {
            this.getAverageAppraisal(weightedScore.toFixed(2) || 0, kpaTypeId);
          } else {
            this.lineManagerAppraisalScore = totalSum.toFixed(2);
          }
        }
      );
    },

    async getAverageAppraisal(score, id) {
      try {
        const { data } = await this.$_getScoreAppraisalFlag(score);
        const found = this.kpaAppraisalSummary.some((el) => el.id === id);
        if (found) {
          this.kpaAppraisalSummary.map((kpaValue) => {
            if (kpaValue.id === id) {
              const summaryVariable = kpaValue;
              summaryVariable.summary = data.ranking.flag.name;
            }
            return {};
          });
        } else {
          this.kpaAppraisalSummary.push({
            id,
            summary: data.ranking.flag.name,
          });
        }
      } catch (err) {
        throw new Error(err);
      }
    },

    getAverageSummary(id, kpaTypeName) {
      let summary;
      const appraisalSummary = [];

      this.employeeAppraisals.forEach((appraisal) => {
        appraisal.summary.forEach((el) => {
          if (el.employeeFlag) {
            appraisalSummary.push(el.kpaTypeName);
          }
        })
      });

      if (appraisalSummary.includes(kpaTypeName) && !this.inputChange.includes(kpaTypeName)) {
        this.kpaAppraisalSummary = [];
        summary = this.getKPATotalSelfAppraisal(kpaTypeName);
      }
      else {
        this.kpaAppraisalSummary.forEach((kpa) => {
          if (kpa.id === id) {
            summary = kpa.summary;
          }
        });
      }
      return summary || "";
    },

    getKPATotalScore(kpaId, kpaTypes) {
      let sum = 0;
      let kpaArray = [];

      if (kpaTypes.id === kpaId) {
        kpaArray = kpaTypes.kpas;
      }

      const hasNull = kpaArray.every((e) => e.selfScore === null);

      if (!hasNull) {
        kpaArray.map((items) => {
          if (items.selfScore) {
            sum += items.selfScore;
          }
          return {};
        });

        this.currentScoreValue = sum;

        if (sum >= 0) {
          return sum.toFixed(2);
        }
      }

      return "";
    },

    getKPATotalManagerScore(kpaId, kpaTypes) {
      let sum = 0;
      let kpaArray = [];

      if (kpaTypes.id === kpaId) {
        kpaArray = kpaTypes.kpas;
      }

      const hasNull = kpaArray.every((e) => e.managerScore === null);

      if (!hasNull) {
        kpaArray.map((items) => {
          if (items.managerScore) {
            sum += items.managerScore;
          }
          return {};
        });

        if (sum >= 0) {
          return sum.toFixed(2);
        }
      }

      return "";
    },

    getKPATotalManagerAppraisal(kpaTypesname) {
      let appraisalVal;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.cycle === this.activeTab) {
          appraisal.summary.filter((summary) => {
            if (summary.kpaTypeName === kpaTypesname) {
              appraisalVal = summary.managerFlag;
            }
            return {};
          });
        }
        return {};
      });

      return appraisalVal || "---";
    },

    getKPATotalSelfAppraisal(kpaTypesname) {
      let appraisalVal;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.cycle === this.activeTab) {
          appraisal.summary.filter((summary) => {
            if (summary.kpaTypeName === kpaTypesname) {
              appraisalVal = summary.employeeFlag;
            }
            return {};
          });
        }
        return {};
      });

      return appraisalVal || "---";
    },

    submitAppraiser(state) {
      const kpaData = [];
      const validateKPA = [];
      this.payload.orgId = this.$orgId;
      this.payload.appraisal_status = "pending";
      this.payload.year = this.$route.params.year;

      if (state === "draft") {
        this.payload.status = state;
      } else {
        this.payload.status = "active";
        this.payload.share = true;
      }
      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas.map(
          ({
            name,
            objective,
            kpis,
            weight,
            outcome,
            selfRating,
            selfScore,
            managerRating,
            managerScore,
            ...rest
          }) => ({
            ...rest,
          })
        );
        const validation = newKPAs.every((item) => item.ratingId);
        validateKPA.push(validation);
        kpaData.push({
          kpa_types_id: kpaType.id,
          kpas: newKPAs,
        });
      });
      if (validateKPA.every((valid) => valid === true)) {
        this.payload.kpa_types = kpaData;
        this.$_setEmployeeAppraisal(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.$router.push({
              name: "EssAppraisals",
              query: { activeTab: "My Appraisals" },
            });
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      } else {
        this.$toasted.error("No scores set for each KPA", { duration: 5000 });
      }
    },

    getEmployeeAppraisal() {
      this.employeeAppraisals = [];
      this.selfAppraisalScore = "";
      this.lineManagerAppraisalScore = "";
      this.approvedScore = "";
      this.appraisalStatus = "";
      this.appraisalState = "";

      this.$_getEmployeeAppraisal(this.$AuthUser.id).then((result) => {
        const responseData = result.data.EmployeeAppraisal;

        responseData.forEach((response) => {
          if (response.appraisal.current_cycle) {
            if (response.appraisal.current_cycle.name === this.activeTab) {
              if (this.finalAppraisal) {
                this.selfAppraisalScore = response.appraisal.self_score;
                this.lineManagerAppraisalScore =
                  response.appraisal.manager_score;
                this.approvedScore = response.appraisal.approved_score;
                this.appraisalStatus = response.appraisal.appraisal_status;
                this.appraisalState = response.appraisal.appraisal_state;
                this.payload.appraisalId = response.appraisal.id;

                response.appraisal.performance_scores.forEach((score) => {
                  this.employeeAppraisals.push({
                    cycle: response.appraisal.current_cycle.name,
                    id: score.kpaId,
                    selfRatingPoint: score.selfRatingPoint,
                    flagId: score.ranking ? score.ranking.flag.name : "",
                    managerFlag: score.manager_ranking
                      ? score.manager_ranking.flag.name
                      : "",
                    selfScore: score.selfScore,
                    outcome: score.outcome,
                    managerRatingPoint: score.managerRatingPoint,
                    managerScore: score.managerScore,
                    summary: response.summary,
                  });

                  this.getAverageAppraisal(score.selfScore, score.kpaTypeId);
                });
              } else {
                this.selfAppraisalScore =
                  response.appraisalSummary.employeeFlag;
                this.lineManagerAppraisalScore =
                  response.appraisalSummary.managerFlag;
                this.approvedScore = response.appraisalSummary.managerFlag;
                this.appraisalStatus = response.appraisal.appraisal_status;
                this.appraisalState = response.appraisal.appraisal_state;
                this.payload.appraisalId = response.appraisal.id;

                response.appraisal.performance_scores.forEach((score) => {
                  this.employeeAppraisals.push({
                    cycle: response.appraisal.current_cycle.name,
                    id: score.kpaId,
                    selfRatingPoint: score.selfRatingPoint,
                    flagId: score.ranking ? score.ranking.flag.name : "",
                    managerFlag: score.manager_ranking
                      ? score.manager_ranking.flag.name
                      : "",
                    selfScore: score.selfScore,
                    outcome: score.outcome,
                    managerRatingPoint: score.managerRatingPoint,
                    managerScore: score.managerScore,
                    summary: response.summary,
                  });

                  this.getAverageAppraisal(score.selfScore, score.kpaTypeId);
                });
              }
            } else {
              delete this.payload.appraisalId;
            }
          }
        });

        this.getAgreements();
      });
    },

    formatEmployeeDate(value) {
      let initialDate = "";
      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");
        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }
      return "";
    },

    getAgreements() {
      this.$_getEmployeeAgreement(this.$AuthUser.id).then((response) => {
        const myAgreements = response.data.EmployeePerformanceAgreements;

        if (myAgreements.length > 0) {
          myAgreements.map((agreement) => {
            if (Number(agreement.year) === Number(this.$route.params.year)) {
              const agreementKPA = agreement.employee_agreement_kpa;

              this.payload.employeeAgreementId = agreement.id;
              this.payload.goalId = this.$route.params.goalId;

              this.$_getOneTemplate(agreement.templateId).then((result) => {
                this.templateKPATypes =
                  result.data.PerformanceTemplate.kpa_types;

                this.templateKPATypes.forEach((kpaType) => {
                  const kpaTypeVar = kpaType;
                  kpaTypeVar.kpas = [];
                  agreementKPA.filter((employeeKpi) => {
                    if (employeeKpi.kpaTypeId === kpaType.id) {
                      kpaType.kpas.push({
                        name: employeeKpi.name,
                        objective: employeeKpi.objective,
                        kpaId: employeeKpi.id,
                        kpis: this.getCriteriaData(employeeKpi.kpis_employee),
                        weight: employeeKpi.weight,
                        outcome: this.getOutcome(employeeKpi.id),
                        selfRating: null,
                        selfScore: this.getScore(employeeKpi.id),
                        ratingId: this.getRatingId(employeeKpi.id),
                        managerRating: this.getManagerRatingId(employeeKpi.id),
                        managerScore: this.getManagerScore(
                          employeeKpi.id,
                          employeeKpi
                        ),
                      });
                    }
                    return {};
                  });
                  const kpaItem = kpaType;
                  kpaItem.selfSummary = null;

                  this.loaded = true;
                  this.loadingAppraisal = false;
                  this.isLoading = false;
                });
              });
            }
            return {};
          });
        } else {
          this.loadingAppraisal = false;
          this.isLoading = false;
        }
      });
    },

    getScore(kpasid) {
      let score;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          score = appraisal.selfScore;
        }
        return {};
      });
      return null || score;
    },

    getRatingId(kpasid) {
      let ratePoint;
      let rating;

      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          if (this.finalAppraisal) {
            ratePoint = appraisal.selfRatingPoint;
          } else {
            ratePoint = appraisal.flagId;
          }
        }
        return {};
      });

      if (this.finalAppraisal) {
        this.ratingOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      } else {
        this.flagOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      }
      return rating || "";
    },

    getOutcome(kpasid) {
      let outcome;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          outcome = appraisal.outcome;
        }
        return {};
      });
      return outcome || "";
    },

    getManagerScore(kpasid) {
      let score;
      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          score = appraisal.managerScore;
        }
        return {};
      });
      return null || score;
    },

    getManagerRatingId(kpasid) {
      let ratePoint;
      let rating;

      this.employeeAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          if (this.finalAppraisal) {
            ratePoint = appraisal.managerRatingPoint;
          } else {
            ratePoint = appraisal.managerFlag;
          }
        }
        return {};
      });

      if (this.finalAppraisal) {
        this.ratingOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      } else {
        this.flagOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      }
      return rating || "";
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);
      const sortedKPIS = kpis.sort((a, b) => a.score.rating - b.score.rating);
      this.Ratings = sortedRatings;
      let kpisVar = kpis;
      kpisVar = sortedKPIS;
      this.Ratings.map((rating, index) => {
        criteriaData.push({
          id: kpisVar[index].id,
          performanceRatingId: rating.id,
          criteria: kpisVar[index].criteria,
        });
        return {};
      });
      return criteriaData;
    },

    checkAppraisalStart(startDate, endDate) {
      const today = new Date();

      const appraisalStartDate = this.setDates(startDate);
      const appraisalEndDate = this.setDates(endDate);

      appraisalEndDate.setDate(appraisalEndDate.getDate() + 1);

      this.appraisalStartDate = appraisalStartDate.toISOString();
      this.appraisalEndDate = this.setDates(endDate).toISOString();

      if (today >= appraisalStartDate && today <= appraisalEndDate) {
        this.startAppraisal = true;
      } else if (today < appraisalStartDate && today <= appraisalEndDate) {
        this.startAppraisal = false;
        this.appraisalMessage = `${this.activeTab} appraisal starts on the
                          ${this.$DATEFORMAT(
                            new Date(this.appraisalStartDate),
                            "MMMM dd, yyyy"
                          )}. You will be notified when it begins.`;
      } else {
        this.startAppraisal = true;
        this.appraisalMessage = `${this.activeTab} appraisal ended on the
                          ${this.$DATEFORMAT(
                            new Date(this.appraisalEndDate),
                            "MMMM dd, yyyy"
                          )}.`;
        this.appraisalEnd = true;
      }

      this.getEmployeeAppraisal();
    },

    getAppraisalCycle() {
      this.tabsItems = [];

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        const appraisalCycle = appraisal[0].cycles.sort(
          (a, b) =>
            this.setDates(a.appraisal_starts) -
            this.setDates(b.appraisal_starts)
        );

        appraisalCycle.map((cycle) => {
          this.tabsItems.push(cycle.name);

          this.cyclesArr.push({
            id: cycle.id,
            name: cycle.name,
          });

          if (this.tabsItems.length === 1) {
            this.finalAppraisal = true;
          }

          if (cycle.name === this.activeTab) {
            const appraisalStartDate = cycle.appraisal_starts;
            const appraisalEndDate = cycle.appraisal_ends;

            this.checkAppraisalStart(appraisalStartDate, appraisalEndDate);
          }
          return {};
        });
      });
    },

    getSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        response.data.PerformanceSettings.map((setting) => {
          const appraisalByWho = setting.appraisailScoringPreference;
          this.checkEditAppraisal = setting.managerCanEditEmployeeAppraisal;
          if (appraisalByWho === "manager_to_appraisee") {
            this.appraisalDisplay = "manager";
            this.KPAHeader.push(
              {
                title: "First Line Manager’s Rating",
                value: "managerRating",
              },
              {
                title: "Performance Outcome",
                value: "outcome",
              }
            );
          } else if (appraisalByWho === "employee_self_appraisal") {
            this.appraisalDisplay = "employee";
            this.KPAHeader.push(
              {
                title: "Self Rating",
                value: "selfRating",
              },
              {
                title: "Performance Outcome",
                value: "outcome",
              }
            );
          } else if (appraisalByWho === "both") {
            this.appraisalDisplay = "both";
            this.KPAHeader.push(
              {
                title: "Self Rating",
                value: "selfRating",
              },
              {
                title: "First Line Manager’s Rating",
                value: "managerRating",
              },
              {
                title: "Performance Outcome",
                value: "outcome",
              }
            );
          }
          return {};
        });
      });
    },

    getUser() {
      this.$_getUser(this.$AuthUser.id).then((result) => {
        this.loadingData = false;
        if (result.data.user.userDesignation.reportingTo !== null) {
          this.employee = true;
        } else {
          this.employee = false;
        }
      });
    },

    formatCommentTime(date) {
      const newDate = new Date(date);
      const hours = newDate.getUTCHours();
      const minutes = newDate.getMinutes();
      return `${1 + ((hours - 1) % 12)}:${minutes
        .toString()
        .padStart(2, "0")} ${hours > 11 ? "PM" : "AM"}`;
    },

    formatCommentDate(date) {
      const newDate = new Date(date);
      return newDate.toDateString();
    },

    getComments(agreementId) {
      this.isOpenComments = true;
      this.loadComments = true;
      this.allComments = [];
      this.$_getAgreementComments(agreementId).then((response) => {
        const { comments } = response.data;
        comments.forEach((comment) => {
          this.allComments.push({
            message: comment.comment,
            time: this.formatCommentTime(comment.date),
            date: this.formatCommentDate(comment.date),
            commentId: comment.id,
            isRead: comment.readStatus,
            receiver: comment.receiver,
            sender: comment.sender,
          });
        });
        this.loadComments = false;
      });
    },

    sendComment() {
      const payload = {
        performanceAgreementId: this.payload.employeeAgreementId,
        senderId: this.$AuthUser.id,
        receiverId: this.$AuthUser.id,
        comment: this.comment,
        sendTo: "admin",
      };
      this.$_postAgreementComments(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.getComments(this.payload.employeeAgreementId);
        this.comment = "";
      });
    },

    deleteComments(id) {
      this.$_deleteAppraisalComments(id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.payload.employeeAgreementId);
        })
        .then((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    markAsRead(commentId) {
      const payload = {
        commentId,
        readStatus: true,
      };
      this.$_markAsRead(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.payload.employeeAgreementId);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getAppraisalFlags() {
      this.$_getAppraisalFlag().then((response) => {
        const { flags } = response.data;
        flags.forEach((flag) => {
          this.flagOptions.push({
            id: flag.id,
            name: flag.name,
          });
        });
      });
    },
  },

  mounted() {
    if (this.$route.params.user === this.$AuthUser.id) {
      this.payload.appraisalFor = this.$AuthUser.id;
      this.payload.appraisalBy = this.$AuthUser.id;
      this.payload.employeeId = this.$AuthUser.id;
    } else {
      this.payload.appraisalFor = this.$route.params.user;
      this.payload.appraisalBy = this.$AuthUser.id;
      this.payload.employeeId = this.$route.params.user;
    }

    this.getAppraisalCycle();
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;

      response.data.PerformanceRatings.map((ratings) => {
        this.ratingOptions.push({
          id: ratings.id,
          name: ratings.rating,
        });
        return {};
      });
    });
    this.getSettings();
    this.getUser();
    this.getAppraisalFlags();
  },
};
</script>

<style>
.fit-content {
  width: fit-content;
}

.input-select:disabled {
  background: #eeeeee;
    color: #878e99;
    border: none;
    background-image: none;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 12px;
}

.input-select {
  border: 1px solid #878e99;
  height: 38px;
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.input-select:focus {
  outline: none;
}
.input-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(/img/down-arrow.f3bc3f05.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 14px;
  padding: 3px 15px;
  width: 100%;
  padding-right: 29px;
  outline: none;
  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 3px;
  height: 40px;
  /* width: 100%; */
  /* width: fit-content; */
}

.button_class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
